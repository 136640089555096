$separator-height: 2px;
$pickerHeightMD: 152px;
$pickerHeightXL: 262px;

.Hero {
	position: relative;
	overflow: hidden;

	&-headingContainer {
		position: absolute;
		right: 50%;
		transform: translateX(50%);
		bottom: 70px;
		width: 100%;
		padding: 0 $gutter_small;
		z-index: 2;

		.hasEliteGuestComponent & {
			transform: translate(50%, -80px);
		}

		@include viewport(medium) {
			padding: 0;
			width: $containerWidth-md;
			bottom: 75px;

			.Heading-subtitle {
				display: inline-block;
				width: 370px;
			}
		}

		@include viewport(large) {
			width: $containerWidth-lg;
			bottom: 80px;

			.Heading-subtitle {
				width: 635px;
			}
		}

		@include viewport(xlarge) {
			width: $containerWidth-xl;
			bottom: 100px;

			.Heading-subtitle {
				width: 1300px;
			}
		}
	}

	.BackgroundImage {
		height: calc(100vw * (4 / 3));
		position: relative;

		@include viewport(medium) {
			height: calc(100vw * (9 / 16));
		}

		&::after {
			@include gradient2transparent($height: 90%);
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.0001) 4.22%,
				rgba(0, 0, 0, 0.9) 61.44%
			);
			bottom: 0;
			content: '';
			display: block;
			position: absolute;
			z-index: 1;

			@include viewport(medium) {
				@include gradient2transparent($height: 55%);
			}

			@include viewport(large) {
				@include gradient2transparent($height: 45%);
			}
		}

		.Heading {
			color: $white;
			text-align: center;
			width: 100%;
			line-height: em(16, 18);

			@include viewport(medium) {
				line-height: em(27, 18);
			}

			&-eyebrow {
				@include typography(h4);
			}

			&-title {
				@include typography(h1);
				margin: 10px 0 (25px - $separator-height);

				&::after {
					background-color: $white;
					content: '';
					display: block;
					height: $separator-height;
					margin: 0 auto;
					position: relative;
					top: (10px - $separator-height);
					width: 40px;
				}

				@include viewport(xlarge) {
					margin: 10px 0 (32px - $separator-height);
				}
				&:lang(ar) {
					letter-spacing: -1px;
				}
			}

			&-subtitle {
				@include typography(c2);
			}
		}
	}

	&-video {
		display: none;
		width: 100%;

		@include viewport(large) {
			display: block;
			position: absolute;
			top: 0;
			z-index: 1;
		}

		@include viewport(xlarge) {
			max-height: 1200px;
		}

		@include rtl {
			// Fixes video centering in IE11 and Edge
			direction: ltr;
		}

		&::after {
			@include gradient2transparent($height: 45%);
			bottom: 0;
			content: '';
			display: block;
			position: absolute;
			z-index: 1;
		}

		.Video {
			&-video {
				position: absolute;
				top: 50%;
				left: 50%;
				min-height: 100%;
				min-width: 100%;
				transform: translate(-50%, -50%);
			}

			&-playButton {
				position: absolute;
				bottom: 16px;
				right: 16px;
				z-index: 3;

				@include rtl {
					left: 16px;
					right: auto;
				}
			}
		}
	}

	&-video,
	.BackgroundImage {
		@include viewport(large) {
			// height should be equal to 90% of viewport height - height of new navbar
			height: calc(90vh - #{$navigationHeight});
			max-height: 1050px;
			min-height: 680px;
		}

		@include viewport(xlarge) {
			max-height: 1200px;
		}
	}

	&-gallery {
		.Gallery-button {
			position: absolute;
			display: block;
			text-align: center;
			bottom: 25px;
			right: 50%;
			transform: translateX(50%);
			z-index: 2;
			white-space: nowrap;

			.hasEliteGuestComponent & {
				transform: translate(50%, -80px);
			}

			@include viewport(medium) {
				bottom: 30px;
				left: 30px;
				right: auto;
				transform: none;

				@include rtl {
					left: auto;
					right: 30px;
				}
			}
		}
	}

	&--privateRetreats,
	&--privateResidences,
	&--meetingsAndEvents {
		.BackgroundImage {
			&::after {
				@include viewport(medium) {
					@include gradient2transparent($height: 70%);
				}

				@include viewport(large) {
					@include gradient2transparent($height: 60%);
				}
			}
		}

		.Hero-video::after {
			@include gradient2transparent($height: 60%);
		}
	}
}

.HomepageHero {
	position: relative;

	.PropertyPicker {
		@include viewport(medium) {
			height: $pickerHeightMD;
		}

		@include viewport(xlarge) {
			height: $pickerHeightXL;
		}
	}

	@include viewport(medium) {
		padding-bottom: $pickerHeightMD / 2;
	}

	@include viewport(xlarge) {
		padding-bottom: $pickerHeightXL / 2;
	}
}

.WeddingsHero {
	position: relative;

	.Hero-headingContainer {
		@include viewport(medium) {
			bottom: 100px;
		}

		@include viewport(large) {
			bottom: 140px;
		}
	}

	@include viewport(large) {
		padding-bottom: 135px;
	}

	&--dark {
		background-color: $black;
	}
}
